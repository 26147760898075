<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" element-loading-text="loading...">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<!-- <el-button type="primary" round icon="el-icon-back" size="mini" @click="$router.push({ name: 'WorkWhPacingList' })">{{ 'undefined' }}</el-button> -->

									<span class="tct_tit">{{$t('i18nn_526831ff87464f45')}}</span>

									<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
										<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
									</el-tooltip> -->
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<!-- <el-button  @click="updatePartnerDataAction($event,$t('i18nn_4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">undefined</el-button> -->
								<!-- <span v-if="!isSel"> -->
								<el-button type="success" icon="el-icon-money" size="small"
									@click="openRechargeAdd()">{{$t('i18nn_9422c7c846cae2ad')}}</el-button>

								<el-button type="primary" icon="el-icon-search" size="small"
									@click="openFeeSer()">
									<!-- {{$t('i18nn_75087cd54a97247c')}} -->
									运费试算/比价
								</el-button>
								
								<!-- <el-button type="primary" icon="el-icon-search" size="small"
									@click="openFeeTest()">运费试算</el-button>
									
								<el-button type="primary" icon="el-icon-search" size="small"
									@click="openFeeCompare()">运费比价</el-button>	 -->
									
								<el-button type="warning" size="small" icon="el-icon-upload2"
									@click="openImportExcel()">{{$t('i18nn_79de6d14b424271b')}}</el-button>

								<el-button type="success" size="small" icon="el-icon-plus"
									@click="openDioalog(null, $t('i18nn_7cdf6a7f9798699b'), 1)">{{$t('i18nn_7cdf6a7f9798699b')}}</el-button>

								<el-button type="warning" size="small" icon="el-icon-check"
									@click="submitOrderRateAction($event, null)">{{$t('i18nn_3d6502c491aca17a')}}</el-button>

								<!-- <el-button type="danger" size="small" icon="el-icon-close" @click="CancelPrintingAction($event, null)">{{$t('i18nn_4ca88dd2e7071844')}}</el-button>
								
								<el-button type="warning" size="small" icon="el-icon-delete" @click="CancelOrderAction($event, null)">{{$t('i18nn_bcef26d98d690aba')}}</el-button> -->

								<!--右边容器-->
								<!-- <slot name="topRight"></slot> -->
								<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<div>
						<div class="filterCon" style="">
							<!-- <ul class="filterConList">
								<li>
									<span>{{$t('i18nn_6cdece641436d7ab')}}</span>
									<el-radio-group v-model="filterData.status" size="medium" @change="initData()">
										<el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">{{ item.label }}</el-radio-button>
									</el-radio-group>
								</li>
							</ul> -->
							<el-row type="flex" justify="end">
								<el-col :span="24">
									<ul class="filterConList">
										<li>
											<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
											<SelAgentUser @change="changeAgentUser"></SelAgentUser>
										</li>
										<!-- <li>
											<span>{{$t('i18nn_43a3586339251494')}}</span>
											<el-date-picker
												v-model="filterData.commitDateArr"
												type="daterange"
												align="right"
												unlink-panels
												range-separator="至"
												:start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
												:end-placeholder="$t('i18nn_46f7be1133b3e2c6')"
												value-format="yyyy-MM-dd"
												format="yyyy-MM-dd"
												size="small"
												@change="initData()"
												:picker-options="pickerOptions"
											></el-date-picker>
										</li> -->
										<li>
											<span>{{$t('i18nn_e020fbe62b0c9833')}}</span>
											<el-input type="text" v-model="filterData.channel" size="small" clearable
												@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('i18nn_5a9aefbc03c778f7')"
												style="width: 180px;" />
										</li>
										<!-- </ul> -->
										<!-- <ul class="filterConList"> -->
										<li>
											<span>{{$t('i18nn_590686fdff40723b')}}</span>
											<el-input type="text" v-model="filterData.relationNo" size="small" clearable
												@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('i18nn_5a9aefbc03c778f7')"
												style="width: 180px;" />
										</li>

										<!-- <li>
											<span>{{ $t('i18nn_29991afa9781d554') }}</span>
											<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
										</li> -->
										<!-- <li>
											<span>{{ $t('i18nn_c944a6686d996ab3') }}</span>
											<whNoSelect @changeData="changWhNo"></whNoSelect>
										</li> -->
										<!-- <li>
											<span>{{ 'undefined' }}</span>

											<el-select
												size="small"
												filterable
												clearable
												v-model="filterData.wh_packing_type"
												:placeholder="$t('i18nn_2ad108ab2c560530')"
												style="width: 200px;"
												@change="initData()"
											>
												<el-option v-for="item in selectOption.wh_packing_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"></el-option>
											</el-select>
										</li> -->

										<!-- <li>
											<span>{{$t('i18nn_590686fdff40723b')}}</span>
											<el-input
												type="text"
												v-model="filterData.packingNo"
												size="small"
												clearable
												@keyup.enter.native="initData()"
												maxlength="50"
												:placeholder="$t('i18nn_5a9aefbc03c778f7')"
												style="width: 180px;"
											/>
										</li> -->
										<!-- <li>
											<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
											<el-input
												type="text"
												v-model="filterData.keyword"
												size="small"
												clearable
												@keyup.enter.native="initData()"
												maxlength="50"
												:placeholder="$t('i18nn_3ee4c9b76289e93a')"
												style="width: 180px;"
											/>
										</li> -->
										<!-- <li>
											<span>{{$t('i18nn_daa60c502de1462f')}}</span>
											<el-input
												type="text"
												v-model="filterData.packingNo"
												size="small"
												clearable
												@keyup.enter.native="initData()"
												maxlength="50"
												:placeholder="$t('i18nn_5a9aefbc03c778f7')"
												style="width: 180px;"
											/>
										</li> -->
										<!-- <li>
											<span>{{ $t('i18nn_6235565b185f0725') }}</span>
											<el-input
												type="text"
												v-model="workNo"
												size="small"
												clearable
												@keyup.enter.native="initData()"
												maxlength="50"
												:placeholder="$t('i18nn_5a9aefbc03c778f7')"
												style="width: 220px;"
											/>
										</li> -->
										<li>
											<el-button icon="el-icon-search" size="small" type="primary"
												@click="initData()">{{ $t('i18nn_1e7246dd6ccc5539') }}</el-button>
										</li>
									</ul>
									<!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"></el-option>
                  </el-select> -->
								</el-col>
								<!-- <el-col :span="5">
                  <span>undefined</span>
                  <el-select filterable clearable size="small" v-model="queryParamObj.goodsType" placeholder="undefined" style="width: 120px;">
                    <el-option v-for="item in selectOption.goods_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
                    </el-option>
                  </el-select>
                </el-col> -->
								<!-- <el-col :span="2"><el-button type="primary" @click="serPageData" size="small">undefined</el-button></el-col> -->
								<!-- <el-col :span="6" class="tableConTopRig">

                </el-col> -->
							</el-row>
						</div>
					</div>

					<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
						:max-height="$store.state.frameConHeightWh1" @row-click="handleCurrentChange"
						@selection-change="handleSelectionChange" :tooltip-effect="'dark'" style="width: 100%" size="small"
						v-loading="loading" element-loading-text="加载中...">
						<el-table-column type="selection" fixed="left" align="center" width="55"></el-table-column>
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

						<!-- "id":"",
                "cusNo":"客户编码",
                "packageNo":"包装编号",
                "goodsSku":"产品代码",
                "goodsImg":"产品图片",
                "goodsName":"产品名称",
                "weightUnit":"重量单位",
                "goodsWeight":"单件重",
                "volumeUnit":"体积单位",
                "goodsLength":"长",
                "goodsWidth":"宽",
                "goodsHeight":"高",
                "goodsCount":"入库件数" -->

						<!-- <el-table-column label="undefined"> -->

						<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')" width="80">
							<template slot-scope="scope">
								<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="success"
									v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="warning"
									v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<!-- <el-tag type="danger" v-else-if="'3'==scope.row.status">{{scope.row.statusName}}</el-tag> -->
								<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							</template>
						</el-table-column>
						
						<el-table-column prop="" :label="'渠道信息'" min-width="120">
							<template slot-scope="scope">
								<div class="recDet">
									<!-- <div class="recItem">
										<span class="">{{$t('i18nn_316e16926411681a')}}</span>:
										<span>{{scope.row.channelName}}</span>
									</div> -->
									<div class="recItem">
										<span class="">{{$t('i18nn_e5c3fd867ea4c8e6')}}</span>:
										<span>{{scope.row.lgsKeyCode}}</span>
									</div>
									<!-- <div class="recItem">
										<span class="">{{$t('i18nn_e716ca411b2142e3')}}</span>:
										<span>{{scope.row.carrier}}</span>
									</div> -->
						
									<div class="recItem">
										<span class="">{{$t('i18nn_3d6d2d670fa63b7c')}}</span>:
										<span>{{scope.row.channel}}</span>
									</div>
									<!-- <div class="recItem">
										<span class="">{{$t('i18nn_3ad90a648c6bfa99')}}</span>:
										<span>{{scope.row.carrierServiceLevel}}</span>
									</div> -->
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
						<!-- <el-table-column prop="channel" :label="$t('i18nn_e020fbe62b0c9833')"></el-table-column> -->
						<!-- <el-table-column prop="relationNo" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column> -->

						<el-table-column prop="relationNo" :label="$t('i18nn_590686fdff40723b')"></el-table-column>

						<!-- <el-table-column prop="cusNo" :label="$t('i18nn_1f4c2f1c8fcb66b7')"></el-table-column> -->

						<!-- <el-table-column prop="packingNo" :label="$t('i18nn_daa60c502de1462f')">
							<template slot-scope="scope">
								{{ scope.row.packingNo }}
							</template>
						</el-table-column> -->

						<!-- <el-table-column prop="containerSize" :label="$t('i18nn_0a84f4853ab4d1ed')"></el-table-column> -->

						<!-- <el-table-column prop="leavePort" :label="$t('i18nn_037f5845b7a88b16')"></el-table-column> -->

						<el-table-column prop="packageInfo" :label="$t('i18nn_b98aefdd52a12eee')" min-width="150">
							<template slot-scope="scope">
								<div v-if="scope.row.packageVo">
									<span><span>L*W*H</span>：{{scope.row.packageVo.length}}*{{scope.row.packageVo.width}}*{{scope.row.packageVo.height}},</span><br />
									<span>{{$t('i18nn_8004917a89ed807b')}}</span>：{{scope.row.packageVo.weight}},
									<span>{{$t('i18nn_b6bf0a07fe26f74f')}}</span>：{{scope.row.packageVo.sizeUnitName}},<br />
									<span>{{$t('i18nn_f9db93b87e08763b')}}</span>：{{scope.row.packageVo.packageCount}},
									<span>{{$t('i18nn_7b16a04efec1728b')}}</span>：{{scope.row.insurance_value}}
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="sendAddr" :label="$t('i18nn_b7367a5ccf9facf3')" min-width="200">
							<template slot-scope="scope">
								<div v-if="scope.row.senderVo">
									{{scope.row.senderVo.firstname}} {{scope.row.senderVo.lastname}},
									{{scope.row.senderVo.company}},
									{{scope.row.senderVo.addr1}},
									{{scope.row.senderVo.addr2}},
									{{scope.row.senderVo.city}},
									{{scope.row.senderVo.province}},
									{{scope.row.senderVo.country}},
									{{scope.row.senderVo.postcode}},
									{{scope.row.senderVo.mobile}},
									{{scope.row.senderVo.mail}},
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="receiveAddr" :label="$t('i18nn_ab3ce045a4241cb0')" min-width="200">
							<template slot-scope="scope">
								<div v-if="scope.row.receiverVo">
									{{scope.row.receiverVo.firstname}} {{scope.row.receiverVo.lastname}},
									{{scope.row.receiverVo.company}},
									{{scope.row.receiverVo.addr1}},
									{{scope.row.receiverVo.addr2}},
									{{scope.row.receiverVo.city}},
									{{scope.row.receiverVo.province}},
									{{scope.row.receiverVo.country}},
									{{scope.row.receiverVo.postcode}},
									{{scope.row.receiverVo.mobile}},
									{{scope.row.receiverVo.mail}},
								</div>
							</template>
						</el-table-column>

						<!-- <el-table-column prop="receiveComplete" :label="$t('i18nn_0df90bded79afc49')"></el-table-column> -->
						
						<el-table-column prop="others" :label="'是否签名'" width="80">
							<template slot-scope="scope">
								<div v-if="scope.row.others">
									<div v-if="'0'==scope.row.others.signature_service">
										<el-tag type="warning" :effect="'plain'" size="small">否</el-tag>
									</div>
									<div v-else-if="!!scope.row.others.signature_service">
										<el-tag type="success" :effect="'plain'" size="small">是</el-tag>
										<div>
											<span>签名类型</span>:
											<span>{{scope.row.others.signature_service}}</span>
										</div>
									</div>
									<div v-else>
										<el-tag type="info" :effect="'plain'" size="small">未知</el-tag>
										<div>
											<span>签名类型</span>:
											<span>{{scope.row.others.signature_service}}</span>
										</div>
									</div>
								</div>
							</template>
						</el-table-column>
						
						<el-table-column prop="others" :label="$t('i18nn_97e49d00bcc4c17c')" >
							<template slot-scope="scope">
								<div v-if="scope.row.others" class="recDet">
									<div class="recItem"><span>label_size:</span><span>{{scope.row.others.label_size}}</span></div>
									<div class="recItem"><span>remark:</span><span>{{scope.row.others.remark}}</span></div>
								</div>
							</template>
						</el-table-column>

						<!-- <el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')"></el-table-column> -->

						<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

						<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column>

						<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="220px" fixed="right">
							<template slot-scope="scope">
								<!-- <div v-if="isSel"> -->
								<div style="">
									<!-- <el-button @click="toDet($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-view">{{ 'undefined' }}</el-button> -->

									<!-- <el-button @click="toPrinterView($event, scope.row, scope.$index)" type="success" size="mini" icon="el-icon-printer">{{ 'undefined' }}</el-button> -->
									<div style="margin-bottom: 5px;">
										<el-button @click="delAction($event, scope.row, scope.$index)" type="danger" size="mini"
											icon="el-icon-minus">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
										
										<el-button @click="editAction($event, scope.row, scope.$index)" type="warning" size="mini"
											icon="el-icon-edit">{{$t('i18nn_6267f3aedf895209')}}</el-button>
									</div>
									
									<div style="margin-bottom: 5px;">	
										<el-button @click="copyAction($event, scope.row, scope.$index)" type="success" size="small" icon="el-icon-copy-document">
											{{$t('i18nn_29f67f0644a78bf3')}}
										</el-button>

										<el-button @click="showDetList($event, scope.row)" type="primary" size="mini"
											icon="el-icon-view">{{$t('FormMsg.details_View')}}</el-button>
									</div>

								</div>

								<!-- <div style="margin-bottom: 10px;">
									<el-button @click="toDet($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-right" v-if="'10' == scope.row.status">
										{{ 'undefined' }}
									</el-button>

									<el-button @click="completeAction($event, scope.row, scope.$index)" type="danger" size="mini" icon="el-icon-finished" v-if="'10' == scope.row.status">
										{{ 'undefined' }}
									</el-button>

								</div> -->
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="tableConPagination"><hy-page-pagination :pagination="pagination"
						v-on:updatePageData="getPageData"></hy-page-pagination></div>
			</div>
		</div>

		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="10px" :title="dialogFormVisibleMsg" append-to-body :visible.sync="dialogFormVisible" custom-class="myFullDialog4"> -->
		<!-- <el-drawer :wrapperClosable="false" :title="dialogFormVisibleMsg" append-to-body :visible.sync="dialogFormVisible"
			:direction="'rtl'" size="1200px">
			<el-form ref="form" :inline="true" :rules="formRules" :size="'small'" :model="form" label-width="150px"
				style="width: 1160px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
				
				<div>

					<el-form-item :label="'lgsKeyCode'" prop="" required>
						<el-select filterable clearable v-model="form.lgsKeyCode" :placeholder="$t('i18nn_2ad108ab2c560530')"
							style="width: 200px;" @change="lgsCodeChange">
							<el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index"
								:label="item.logistics+'('+item.codeName+')'" :value="item.code">
								<div style="display: flex; justify-content: space-between;">
									<div>{{ item.logistics }}({{ item.codeName }})</div>
									<div style="color: #8492a6; font-size: 13px">
										<span v-if="0 == item.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
										<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
									</div>
								</div>
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="$t('i18nn_e020fbe62b0c9833')" prop="" required>
						<div v-if="3==dialogFormVisibleType">
							<el-switch
							  style="display: block"
							  v-model="allChannel"
								:active-value="true"
								:inactive-value="false"
							  :active-text="$t('i18nn_16853bda54120bf1')"
							  :inactive-text="$t('i18nn_7a22cefca1bca21d')">
							</el-switch>
							<div v-if="allChannel">
								<el-tag v-for="(item,index) in allChannelList" :key="index">
									{{ item.name }}
								</el-tag>
							</div>
							
							<el-select filterable clearable v-model="form.channel" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;" v-if="!allChannel">
								<el-option v-for="(item,index) in selectOption.channelList" :key="index" :label="item.name"
									:value="item.key">
									<div class="sel_option_s1" style="">
										<span class="sel_option_name">{{ item.name }}</span>
										<span class="sel_option_code" style="">{{ item.key }}</span>
									</div>
								</el-option>
							</el-select>
						</div>
						<div v-else>
							<el-select filterable clearable v-model="form.channel" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;">
								<el-option v-for="(item,index) in selectOption.channelList" :key="index" :label="item.name"
									:value="item.key">
									<div class="sel_option_s1" style="">
										<span class="sel_option_name">{{ item.name }}</span>
										<span class="sel_option_code" style="">{{ item.key }}</span>
									</div>
								</el-option>
							</el-select>
						</div>
						
						
					</el-form-item>

					<el-form-item :label="$t('i18nn_590686fdff40723b')" prop="">
						<el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.relationNo"></el-input>
					</el-form-item>

					<el-card style="" v-if="selLgsData && selLgsData.descJson && selLgsData.descJson.length>0">
						<div slot="header" class="">
							<div style="display: flex;">
								<div>{{ selLgsData.logistics }}({{ selLgsData.codeName }})</div>
								<div style="color: #8492a6; font-size: 13px">
									<span v-if="0 == selLgsData.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
									<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
								</div>
							</div>
						</div>
						<div>
							<el-descriptions border size="mini" :labelStyle="{ 'background': '#ecf5ff'}">
								<el-descriptions-item :label="item2.name" v-for="(item2, index2) in selLgsData.descJson"
									:key="index2">{{ item2.value }}</el-descriptions-item>
							</el-descriptions>
						</div>
					</el-card>

					<el-card style="">
						<div slot="header" class="clearfix">
							<div style="display: flex; justify-content: space-between;">
								<div>
									<div>{{$t('i18nn_4851293ecdd539ed')}}</div>
								</div>
								<div style="display: flex;">
									<div style="">
										<span>FBA仓库编号</span>
										<el-input size="small" clearable v-model="fbaAddrCode" style="width:200px;">
											<el-button slot="append" type="primary" icon="el-icon-search"
												@click="searchFbaAddress(1)"></el-button>
										</el-input>
									</div>
									<div>
										<span>HYTX仓库</span>
										<whNoAllSelect ref="whNoAllSelect" @changeData="changWhNo" @loadAllSuccess="whLoadSuccess">
										</whNoAllSelect>
									</div>
								</div>

							</div>

						</div>

						<div>
							<el-form-item :label="$t('i18nn_caed6cfc34b968aa')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.firstname"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_f9c0ee1156f1e254')" prop=""><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.lastname"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_ab653780491c0d4b')" prop=""><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.company"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_8db45b615b72b7a8')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.receiverVo.addr1"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_3e429a7c7cf3cab2')" prop=""><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.receiverVo.addr2"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.receiverVo.city"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_aa2bc6dc3cafeb11')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.province"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.country"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.postcode"></el-input></el-form-item>

							<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.mobile"></el-input></el-form-item>
							<el-form-item :label="'mail'" prop=""><el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.receiverVo.mail"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_0bbce74881c893df')" prop="" required>
								<el-select filterable clearable v-model="form.receiverVo.addr_type"
									:placeholder="$t('i18nn_2ad108ab2c560530')">
									<el-option :label="$t('i18nn_6b57a5faf337b87b')" :value="'0'"></el-option>
									<el-option :label="$t('i18nn_a9cbcfc68126f75a')" :value="'1'"></el-option>
									<el-option :label="$t('i18nn_af200a78a2a44559')" :value="'2'"></el-option>
								</el-select>

								<el-button style="margin-left: 5px;" type="primary" icon="el-icon-discover" size=""
									@click="checkAddress">{{$t('i18nn_6a4dc4a49612041e')}}</el-button>
							</el-form-item>

						</div>
					</el-card>

					<el-card style="" v-if="onlySenderLgsCode.includes(form.lgsKeyCode)">
						<div slot="header" class=""><span>{{$t('i18nn_479ea0356ec72989')}}</span>(({{form.lgsKeyCode}}))</div>
						<div>
							<el-card :title="$t('i18nn_2bfaf322a81c4e8c')" style="margin-bottom: 10px;">
								<div slot="header" class=""><span>{{$t('i18nn_f1e789e4700e15e3')}}</span></div>
								<el-radio-group v-model="selCopeShip" @change="ChangCopeShip">
									<div>
										<el-radio :label="item.uuid" v-for="(item,index) in CopeShipfromList"
											:key="index">{{item.name}}</el-radio>
									</div>
								</el-radio-group>
							</el-card>


							<el-form-item :label="$t('i18nn_2dfd6348829d984f')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.firstname"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_e03f8082906c4de8')" prop=""><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.lastname"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_ab653780491c0d4b')" prop=""><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.company"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_8db45b615b72b7a8')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr1"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_3e429a7c7cf3cab2')" prop=""><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr2"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.city"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_aa2bc6dc3cafeb11')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.province"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.country"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.postcode"></el-input></el-form-item>

							<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.mobile"></el-input></el-form-item>
							<el-form-item :label="'mail'" prop=""><el-input disabled :placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.mail"></el-input></el-form-item>
						</div>
					</el-card>
					<el-card style="" v-else>
						<div slot="header" class="">
							<div style="display: flex; justify-content: space-between;">
								<div>
									<div>{{$t('i18nn_479ea0356ec72989')}}</div>
								</div>
								<div style="display: flex;">
									<div style="">
										<span>FBA仓库编号</span>
										<el-input size="small" clearable v-model="fbaAddrCode2" style="width:200px;">
											<el-button slot="append" type="primary" icon="el-icon-search"
												@click="searchFbaAddress(2)"></el-button>
										</el-input>
									</div>
									<div>
										<span>HYTX仓库</span>
										<whNoAllSelect ref="whNoAllSelect2" @changeData="changWhNo2"></whNoAllSelect>
									</div>
								</div>

							</div>
						</div>

						<div>
							<el-form-item :label="$t('i18nn_2dfd6348829d984f')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.firstname"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_e03f8082906c4de8')" prop=""><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.lastname"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_ab653780491c0d4b')" prop=""><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.company"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_8db45b615b72b7a8')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr1"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_3e429a7c7cf3cab2')" prop=""><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr2"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.city"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_aa2bc6dc3cafeb11')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.province"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required><el-input disabled
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.country"></el-input></el-form-item>
							<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.postcode"></el-input></el-form-item>

							<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="" required><el-input
									:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.mobile"></el-input></el-form-item>
							<el-form-item :label="'mail'" prop=""><el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')"
									v-model="form.senderVo.mail"></el-input></el-form-item>
						</div>
					</el-card>

					<el-card style="">
						<div slot="header" class="clearfix"><span>{{$t('i18nn_b98aefdd52a12eee')}}</span></div>
						<div>
							<el-form-item :label="$t('i18nn_0e60303b30d5d4b4')" prop="" required><el-input-number
									v-model="form.packageVo.length" controls-position="right"></el-input-number></el-form-item>
							<el-form-item :label="$t('i18nn_6cd31871f8528dd5')" prop="" required><el-input-number
									v-model="form.packageVo.width" controls-position="right"></el-input-number></el-form-item>
							<el-form-item :label="$t('i18nn_93c9ea4a0e52c641')" prop="" required><el-input-number
									v-model="form.packageVo.height" controls-position="right"></el-input-number></el-form-item>
							<el-form-item :label="$t('i18nn_96f1a4b17e75892d')" prop="" required><el-input-number
									v-model="form.packageVo.weight" controls-position="right"></el-input-number></el-form-item>

							<el-form-item :label="$t('i18nn_f9db93b87e08763b')" prop="" required><el-input-number
									v-model="form.packageVo.packageCount" controls-position="right"></el-input-number></el-form-item>
							<el-form-item :label="$t('i18nn_b6bf0a07fe26f74f')" prop="" required>
								<el-select filterable clearable v-model="form.packageVo.sizeUnit"
									:placeholder="$t('i18nn_2ad108ab2c560530')">
									<el-option v-for="item in selectOption.wh_size_unit" :key="item.code"
										:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item :label="$t('i18nn_7b16a04efec1728b')" prop="">
								<span class="red">(USD)</span>
								<el-input-number v-model="form.packageVo.insurance_value" controls-position="right"></el-input-number>
							</el-form-item>


						</div>
					</el-card>

					<el-card style="">
						<div slot="header" class="clearfix">
							<span>{{$t('i18nn_97e49d00bcc4c17c')}}</span>
						</div>
						<div>
							<el-form-item :label="$t('i18nn_19252a10a7c4755e')" prop="">
								<el-select filterable clearable v-model="form.others.label_size"
									:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
									<el-option v-for="item in selectOption.wh_label_size" :key="item.code"
										:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item :label="$t('i18nn_7e80cfa637ec486b')" prop="">
								<el-select filterable clearable v-model="form.others.signature_service"
									:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
									<el-option v-for="(item,index) in selectOption.wh_signature_service" :key="index" :label="item.name"
										:value="item.key"></el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item :label="$t('Storage.tableColumn.remark')">
								<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.others.remark"
									:maxlength="1000" show-word-limit></el-input>
							</el-form-item>
						</div>
					</el-card>
				</div>

			</el-form>
			
			<el-card style="" v-loading="loading" v-if="3==queryFeeRateType">
				<div>
					<el-descriptions title="查询试算结果">
						<el-descriptions-item label="渠道名称">{{queryFeeRateResClac.codeName}}</el-descriptions-item>
						<el-descriptions-item label="类型">{{queryFeeRateResClac.addTypeName}}</el-descriptions-item>
						<el-descriptions-item label="计算重量">{{queryFeeRateResClac.rateWeight}}</el-descriptions-item>
						<el-descriptions-item label="地址区间">{{queryFeeRateResClac.zone}}</el-descriptions-item>
					</el-descriptions>
			
					<el-table ref="multipleTable4" :data="queryFeeRateResClac.serviceArr" highlight-current-row stripe :border="true"
						style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="service" :label="'服务'"></el-table-column>
						<el-table-column prop="totalPrice" :label="'价格'"></el-table-column>
						<el-table-column prop="" :label="'明细'">
							<template slot-scope="scope">
								<div v-if="scope.row.fees">
									<div v-for="(item, index) in scope.row.fees" :key="index" style="">
										<span>{{item.feeTypeName}}</span>：<span>{{item.price}}</span>
									</div>
								</div>
							</template>
						</el-table-column>
					</el-table>
					</el-table>
			
				</div>
			</el-card>
			
			<div v-loading="loading" v-else-if="4==queryFeeRateType">
				<div style="padding: 10px;">
					<span style="font-size: 18px;font-weight: bold;">查询比价结果</span>
				</div>
				<el-card style="" v-for="(queryFeeRateResItem,index) in queryFeeRateResAll" :key="index">
					
					<div>
						<el-descriptions title="">
							<el-descriptions-item label="渠道名称">{{queryFeeRateResItem.codeName}}</el-descriptions-item>
							<el-descriptions-item label="类型">{{queryFeeRateResItem.addTypeName}}</el-descriptions-item>
							<el-descriptions-item label="计算重量">{{queryFeeRateResItem.rateWeight}}</el-descriptions-item>
							<el-descriptions-item label="地址区间">{{queryFeeRateResItem.zone}}</el-descriptions-item>
						</el-descriptions>
				
						<el-table ref="multipleTable4" :data="queryFeeRateResItem.serviceArr" highlight-current-row stripe :border="true"
							style="width: 100%" size="small">
							<el-table-column type="index" fixed="left" width="50" align="center"
								:label="$t('7b1c2b1adc920d9c')"></el-table-column>
							<el-table-column prop="service" :label="'服务'"></el-table-column>
							<el-table-column prop="totalPrice" :label="'价格'"></el-table-column>
							<el-table-column prop="" :label="'明细'">
								<template slot-scope="scope">
									<div v-if="scope.row.fees">
										<div v-for="(item, index) in scope.row.fees" :key="index" style="">
											<span>{{item.feeTypeName}}</span>：<span>{{item.price}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
						</el-table>
				
					</div>
				</el-card>
			</div>

			<el-card style="" v-loading="loading" v-else>
				<div slot="header" class="clearfix">
					<span style="color:red; font-weight: bold;">{{$t('i18nn_45b0c5bc56871f57')}}</span>
				</div>
				<div>
					
					<el-table ref="multipleTable4" :data="queryFeeRateRes" highlight-current-row stripe :border="true"
						style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="carrier" :label="'Carrier'"></el-table-column>
						<el-table-column prop="carrierServiceLevel" :label="'CarrierServiceLevel'"></el-table-column>
						<el-table-column prop="channel" :label="'Channel'"></el-table-column>
						<el-table-column prop="price" :label="'Price'"></el-table-column>
						<el-table-column prop="" :label="'Details'">
							<template slot-scope="scope">
								<div v-if="scope.row.feeDetail">
									<div v-for="(val, key, index) in scope.row.feeDetail" :key="key">
										<span>{{key}}</span>：<span>{{val}}</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="200px" fixed="right"
							v-if="1 == dialogFormVisibleType || 2 == dialogFormVisibleType">
							<template slot-scope="scope">
								<div v-if="scope.row && scope.row.channel">
									<el-button @click="selAndSubmit($event, scope.row, scope.$index)" type="primary" size="mini"
										icon="el-icon-tickets">{{$t('i18nn_ff89821d70424147')}}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					
				</div>
			</el-card>

			<div class="drawer-footer">
				
				<el-button v-if="1 == dialogFormVisibleType || 2 == dialogFormVisibleType" type="primary" icon="el-icon-tickets"
					@click="submitForm('form', 1)">
					{{ $t('FormMsg.Save') }}
				</el-button>

				<el-button type="warning" icon="el-icon-search"
					@click="submitForm('form', 2)">{{$t('i18nn_ea74b89efa9dbdb8')}}</el-button>
				
				<el-button type="warning" icon="el-icon-search"
					@click="submitForm('form', 3)">运费试算</el-button>
				
				<el-button type="warning" icon="el-icon-search"
					@click="submitForm('form', 4)">运费比价</el-button>

				<el-button @click="resetForm('form')">{{$t('i18nn_dc2f299dc3aeffb3')}}</el-button>


			</div>
		</el-drawer> -->
		
		<!-- 新增，编辑，查询 -->
		<ExpressSheetListAdd :openTime="ExpressSheetListAddOpenTime" 
			:openRow="ExpressSheetListAddRow" 
			:type="dialogFormVisibleType" :typeTitle="dialogFormVisibleMsg" 
			 :copy="dialogFormVisibleIsCopy" @success="SerAddBack()">
		</ExpressSheetListAdd>
		
		<!--  -->
		<RechargeRecordAdd :openTime="openRechargeAddTime" :openRow="{}" @postSuccess="RechargeAddBack()">
		</RechargeRecordAdd>
		<!--  -->
		<ExpressSheetListImport :openTime="openImportTime" @postSuccess="ExcelSheetImportSuccess()">
		</ExpressSheetListImport>
		
		<!-- 运费试算 -->
		<!-- <thirdLogiCarrFeePriceTestClac :openTime="PriceTestClacOpenTime" :row="PriceTestClacRow" @postSuccess="priceSuccessBack()">
		</thirdLogiCarrFeePriceTestClac> -->
		
		<!-- 运费比价 -->
		<!-- <thirdLogiCarrFeePriceComparePrice :openTime="ComparePriceOpenTime" :row="ComparePriceClacRow" @postSuccess="priceSuccessBack()">
		</thirdLogiCarrFeePriceComparePrice> -->
		
		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('FormMsg.details_View')" append-to-body :visible.sync="dialogShowDet"
			:direction="'rtl'" size="1200px">
			<div style="">
				<ExpressSheetDetList :openTime="detOpenTime" :exprId="exprId"></ExpressSheetDetList>
			</div>
			<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div> -->
		</el-drawer>
		<!-- </el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhFuerthertryPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	import {
		clearObjectVal
	} from '@/utils/utils.js';
	import {
		getDicData
	} from '@/axios/common.js';
	// import WhGoods from './WhGoods.vue';
	// import whFileView from './components/whFileView.vue';

	// import whPrinterInGoods from './components/whPrinterInGoods.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	// import whNoAllSelect from '@/components/StorageCenter/components/whNoAllSelect.vue';

	import ExpressSheetListImport from './ExpressSheetListImport.vue';
	import ExpressSheetListAdd from './ExpressSheetListAdd.vue';

	import RechargeRecordAdd from '@/components/StorageCenter/account/RechargeRecordAdd.vue';
	import ExpressSheetDetList from './ExpressSheetDetList.vue';
	// import thirdLogiCarrFeePriceTestClac from './thirdLogiCarrFeePriceTestClac.vue';
	// import thirdLogiCarrFeePriceComparePrice from './thirdLogiCarrFeePriceComparePrice.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	
	export default {
		components: {
			// whFileView,
			// cusSelFuzzy,
			SelAgentUser,
			// whNoAllSelect,
			ExpressSheetListImport,
			ExpressSheetListAdd,
			RechargeRecordAdd,
			whExcelCustom,
			ExpressSheetDetList,
			// thirdLogiCarrFeePriceTestClac,
			// thirdLogiCarrFeePriceComparePrice
			// whPrinterInGoods
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},

				dialogShowDet: false,
				detOpenTime: '',
				exprId: null,

				openImportTime: '',
				
				//运费试算
				// PriceTestClacOpenTime:'',
				// PriceTestClacRow:{},
				
				//运费比价
				// ComparePriceOpenTime:'',
				// ComparePriceClacRow:{},

				pickerOptions: this.$PickerDate.pickerOptions_1(),
				//线下充值
				openRechargeAddTime: '',
				//上传图片
				// dialogImgUploadVisible: false,
				// imgId: this.$store.getters.getUserInfo.id,
				// openRowData: {},
				// openRowIndex: {},
				multipleSelection: [],
				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				//确认收货
				// dialogIsLiveUpload: false,
				// IsLiveUploadData: {
				// 	id: '',
				// 	isLiveUpload: '0'
				// },

				//打开入库选择框
				// dialogSelVisible: false,

				// openRowData: {},
				// openRowIndex: {},】
				// dialogFormVisible: false,
				dialogFormVisibleMsg: '',
				dialogFormVisibleType: '1',
				dialogFormVisibleIsCopy: false,
				// allChannel: true, //是否全部渠道
				// allChannelList: [],
				
				ExpressSheetListAddRow:{},
				ExpressSheetListAddOpenTime:"",
				// form: {
				// 	lgsKeyCode: 'Furthertry',
				// 	channel: 'I_UPS_08873_HYTX',
				// 	relationNo:'',
				// 	receiverVo: {
				// 		firstname: 'Patrick Bagnell',
				// 		lastname: 'Bagnell',
				// 		company: '1',
				// 		addr1: '108 Glenbrook Blvd',
				// 		addr2: '1',
				// 		city: 'Mount Laurel',
				// 		province: 'NJ',
				// 		postcode: '08054-1026',
				// 		country: 'US',
				// 		mobile: '+1 213-442-1463 ext. 44136',
				// 		mail: '1',
				// 		addr_type: '1'
				// 	},
				// 	senderVo: {
				// 		firstname: 'Mario A. Gordillo Jr',
				// 		lastname: '1',
				// 		company: 'addr1',
				// 		addr1: '710 OLEANDER ST',
				// 		addr2: '1',
				// 		city: 'LAREDO',
				// 		province: 'TX',
				// 		postcode: '78046-8592',
				// 		country: 'US',
				// 		mobile: '+1 929-436-4790 ext. 52955',
				// 		mail: '1'
				// 	},
				// 	packageVo: {
				// 		weight: '1',
				// 		length: '1',
				// 		height: '1',
				// 		width: '1',
				// 		packageCount: '1',
				// 		sizUnit: '1'
				// 	},
				// 	others: {
				// 		label_size: '1',
				// 		remark: ''
				// 	}
				// },
				// selLgsData: {},

				// CopeShipfromList: [],
				// selCopeShip: '',
				// 发货地址不可编辑的LgsCode
				// onlySenderLgsCode: ['cope_fedex', 'cope_ups', 'cope_usps', 'paramount'],

				// form: {
				// 	lgsKeyCode: '',
				// 	channel: '',
				// 	relationNo: '',
				// 	receiverVo: {
				// 		firstname: '',
				// 		lastname: '',
				// 		company: '',
				// 		addr1: '',
				// 		addr2: '',
				// 		city: '',
				// 		province: '',
				// 		postcode: '',
				// 		country: '',
				// 		mobile: '',
				// 		mail: '',
				// 		addr_type: ''
				// 	},
				// 	senderVo: {
				// 		firstname: '',
				// 		lastname: '',
				// 		company: '',
				// 		addr1: '',
				// 		addr2: '',
				// 		city: '',
				// 		province: '',
				// 		postcode: '',
				// 		country: '',
				// 		mobile: '',
				// 		mail: ''
				// 	},
				// 	packageVo: {
				// 		weight: '',
				// 		length: '',
				// 		height: '',
				// 		width: '',
				// 		packageCount: '',
				// 		sizeUnit: '',
				// 		insurance_value: ''
				// 	},
				// 	others: {
				// 		rateId: '',
				// 		label_size: '',
				// 		signature_service: '', //签名服务	不传或传0: 无签名 、1：Adult Signature 2: Direct Signature
				// 		remark: ''
				// 	}
				// },

				// formRules: {
				// 	userId: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'change'
				// 	}],
				// 	chargeType: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'change'
				// 	}]
				// 	// feePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
				// },

				// queryFeeRateRes: [],
				// queryFeeRateResClac:{},//试算
				// queryFeeRateResAll:[],//比价
				
				// queryFeeRateType: '',//试算类型
				
				// hasAutoCount: false,

				// fileRelationId:'',
				// FileUploadOpenTime: '',
				// fileDataList: [],

				//打印
				// PrinterOpenTime: false,
				// PrinterData: [],

				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				// fbaAddrCode: "", //FBA仓库地址编号
				// fbaAddrCode2: "",
				// whNoList: [], //仓库列表

				selectOption: {
					// wh_no:[],
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_label_size: [],
					// wh_size_unit: [],
					// biz_wh_lgskey_code: [],
					// channelList: [],
					// wh_signature_service: [],
					// statusList: [
					// 	{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '10',
					// 		label: this.$t('i18nn_1e2279a00c4336a4')
					// 	},
					// 	{
					// 		value: '20',
					// 		label: this.$t('i18nn_3ece0cc00f1da8de')
					// 	}
					// ]
				},
				// workNo: '', //工单号，必传
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					status: '',

					channel: '',
					relationNo: '',
					agentUser: '',
					cusNo: '',
					keyword: '',
					// wh_packing_type: '',

					commitDateArr: []
					// goodsSku: '',
					// goodsName:""
				}
			};
		},
		activated() {
			// if (this.$route.query && this.$route.query.workNo) {
			// 	this.workNo = this.$route.query.workNo;
			// }
			// this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {
			this.initData();
			// this.getWhNoData();
			// this.getDicData();
			//数据字典
			// getDicData(['wh_weight_unit', 'wh_vol_unit', 'wh_label_size', 'wh_size_unit'],
			// 	(data) => {
			// 		this.selectOption.wh_weight_unit = data['wh_weight_unit'];
			// 		this.selectOption.wh_vol_unit = data['wh_vol_unit'];
			// 		this.selectOption.wh_label_size = data['wh_label_size'];
			// 		this.selectOption.wh_size_unit = data['wh_size_unit'];
			// 		// 选择时带的数据，不需要从数据字典获取
			// 		// this.selectOption.wh_signature_service = data['wh_signature_service'];
			// 	});

			// this.getLGSByCodeData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
				// this.getDicData();
			},

			//导出 excel
			exportExcel() {
				let columns = [
					// {
					// 	title: this.$t('i18nn_abf9f4b8c93d641e'),
					// 	key: 'cusName'
					// },
					{
						title: this.$t('i18nn_590686fdff40723b'),
						key: 'senderAddr'
					},
					{
						title: this.$t('i18nn_1f4c2f1c8fcb66b7'),
						key: 'commitDate'
					},
					{
						title: this.$t('i18nn_e020fbe62b0c9833'),
						key: 'orderNumber'
					},
					{
						title: this.$t('i18nn_daa60c502de1462f'),
						key: 'carrier'
					},
					{
						title: this.$t('i18nn_0a84f4853ab4d1ed'),
						key: 'tracking'
					},
					{
						title: this.$t('i18nn_037f5845b7a88b16'),
						key: 'rate'
					},
					// {
					// 	title: 'SKU#数量#重量',
					// 	key: 'sku',
					// 	types: 'custom',
					// 	formatter:function(row){
					// 		return row.expressSkuList.map(item=>{
					// 			return item.goodsSku+"#"+item.quantity+"#"+item.totalWeight;
					// 		}).join(",\r");
					// 	}
					// },
					{
						title: this.$t('i18nn_6cdece641436d7ab'),
						key: 'sku',
						types: 'custom',
						formatter: function(row) {
							return row.expressSkuList
								.map(item => {
									return item.goodsSku;
								})
								.join(',');
						}
					},
					{
						title: this.$t('i18nn_b98aefdd52a12eee'),
						key: 'quantity',
						types: 'custom',
						formatter: function(row) {
							return row.expressSkuList
								.map(item => {
									return item.quantity;
								})
								.join(',');
						}
					},
					{
						title: this.$t('i18nn_b7367a5ccf9facf3'),
						key: 'totalWeight',
						types: 'custom',
						formatter: function(row) {
							return row.expressSkuList
								.map(item => {
									return item.totalWeight;
								})
								.join(',');
						}
					},
					{
						title: this.$t('i18nn_ab3ce045a4241cb0'),
						key: 'packingNo'
					},
					{
						title: this.$t('i18nn_0df90bded79afc49'),
						key: 'attachments',
						types: 'custom',
						formatter: function(row) {
							return row.attachments
								.map(item => {
									return item.url;
								})
								.join(',');
						}
					},
					{
						title: this.$t('i18nn_97e49d00bcc4c17c'),
						key: 'remark'
					},
					{
						title: this.$t('i18nn_15b3627faddccb1d'),
						key: 'remark'
					}
				];
				let Data = this.tableData;

				// let data_dom = JQ('#ex_table').find(".el-table__body");
				// let obj_key_img = {};
				// // console.log(data_dom.find("tr"));
				// data_dom.find("tr").each((index, domEle)=>{
				// 	// console.log("index",index);
				// 	let imgObj = JQ(domEle).find("td .cell img");
				// 	// console.log("imgObj",imgObj);
				// 	if(imgObj){
				// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
				// 	}
				// })
				// // console.log("obj_key_img",obj_key_img);
				// Data.forEach(item=>{
				// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
				// })
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'ExpressSheetList';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
			},

			//打印
			// toPrinterView(event, row) {
			// 	event.stopPropagation();
			// 	// this.PrinterOpenTime = new Date().getTime();
			// 	// this.PrinterData = [];
			// 	let routeData = this.$router.resolve({ name: 'whPrinterReceptionList', query: { packId: row.id } });
			// 	window.open(routeData.href, '_blank');

			// 	// this.$router.push({name:'whPrinterInGood',query:{packId:row.id},target:"_blank"});
			// },

			// toDet(event, row) {
			// 	event.stopPropagation();
			// 	this.$router.push({ name: 'WhInGoodsDetList2', query: { packId: row.id } });
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.filterData.cusNo = data.cusNo;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			//清空收件人地址
			// clearReceiverVo() {
			// 	this.form.receiverVo = clearObjectVal(this.form.receiverVo);
			// 	this.form.receiverVo.country = 'US';
			// },
			//清空发件人地址
			// clearSenderVo() {
			// 	this.form.senderVo = clearObjectVal(this.form.senderVo);
			// 	this.form.senderVo.country = 'US';
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	// this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// 	let findWh = this.whNoList.find(item => {
			// 		return item.whNo == data.code;
			// 	});
			// 	if (!!findWh) {
			// 		this.form.receiverVo.province = findWh.state;
			// 		this.form.receiverVo.city = findWh.city;
			// 		this.form.receiverVo.addr1 = findWh.address;
			// 		this.form.receiverVo.addr2 = "";
			// 		this.form.receiverVo.postcode = findWh.postalCode;
			// 		this.form.receiverVo.mobile = findWh.phoneNumber;
			// 		this.form.receiverVo.mail = findWh.email;
			// 		this.form.receiverVo.company = findWh.company;
			// 		this.form.receiverVo.firstname = findWh.name;
			// 		this.form.receiverVo.lastname = "";
			// 	}
			// 	// this.form.receiverVo.country = findWh.country;

			// 	// company: '',
			// 	// addr1: '',
			// 	// addr2: '',
			// 	// city: '',
			// 	// province: '',
			// 	// postcode: '',
			// 	// country: '',
			// 	// mobile: '',
			// 	// mail: '',

			// },
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	// this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// 	let findWh = this.whNoList.find(item => {
			// 		return item.whNo == data.code;
			// 	});
			// 	if (!!findWh) {
			// 		// this.form.receiverVo.country = findWh.country;
			// 		this.form.senderVo.province = findWh.state;
			// 		this.form.senderVo.city = findWh.city;
			// 		this.form.senderVo.addr1 = findWh.address;
			// 		this.form.senderVo.addr2 = "";
			// 		this.form.senderVo.postcode = findWh.postalCode;
			// 		this.form.senderVo.mobile = findWh.phoneNumber;
			// 		this.form.senderVo.mail = findWh.email;
			// 		this.form.senderVo.company = findWh.company;
			// 		this.form.senderVo.firstname = findWh.name;
			// 		this.form.senderVo.lastname = "";
			// 	}
			// 	// company: '',
			// 	// addr1: '',
			// 	// addr2: '',
			// 	// city: '',
			// 	// province: '',
			// 	// postcode: '',
			// 	// country: '',
			// 	// mobile: '',
			// 	// mail: '',

			// },
			// whLoadSuccess(list) {
			// 	this.whNoList = list;
			// },
			//分页的筛选项数据
			pageFilterData() {
				let commitDateArr = this.filterData.commitDateArr;
				let startCommitDate = '';
				let endCommitDate = '';
				if (commitDateArr && commitDateArr.length == 2) {
					startCommitDate = commitDateArr[0];
					endCommitDate = commitDateArr[1];
				}

				// channel: '',
				// relationNo: '',

				return {
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,

					startCommitDate: startCommitDate ? startCommitDate : null,
					endCommitDate: endCommitDate ? endCommitDate : null,

					// workNo: this.workNo ? this.workNo : null,
					status: this.filterData.status ? this.filterData.status : null,
					channel: this.filterData.channel ? this.filterData.channel : null,
					cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
					// keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// packageType: this.filterData.wh_packing_type ? this.filterData.wh_packing_type : null,

					relationNo: this.filterData.relationNo ? this.filterData.relationNo : null
				};
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhFuerthertryPageList, filterData)
					.then(({
						data
					}) => {

						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('商家账单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading_load = false;
					});
			},
			//明细
			showDetList($event, row) {
				$event.stopPropagation();
				this.dialogShowDet = true;
				this.detOpenTime = new Date().getTime();
				this.exprId = row.id;
			},
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//导入成功后回调
			ExcelSheetImportSuccess(val) {
				console.log(val);
				this.initData();
			},

			//线下充值
			openRechargeAdd() {
				this.openRechargeAddTime = new Date().getTime();
			},
			//线下充值-回调
			RechargeAddBack() {
				this.$router.push({
					name: "RechargeRecord"
				})
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	if (this.$refs[formName]) {
			// 		this.$refs[formName].resetFields();
			// 	} else {
			// 		console.log('this.$refs[formName]', this.$refs[formName]);
			// 	}
			// 	Object.keys(this.form).forEach(key => {
			// 		if (Object.prototype.toString.call(this.form[key]) === '[object Object]') {
			// 			Object.keys(this.form[key]).forEach(key2 => {
			// 				this.form[key][key2] = null;
			// 			});
			// 		} else {
			// 			this.form[key] = null;
			// 		}
			// 	});
			// 	// this.form.lgsKeyCode = "Furthertry";
			// 	this.form.receiverVo.country = 'US';
			// 	this.form.senderVo.country = 'US';

			// 	// this.form.packageVo.sizUnit = '1';
			// 	this.form.others.label_size = '1';

			// 	this.queryFeeRateRes = [];

			// 	console.log();
			// 	// this.CateValue = [];
			// 	// if (this.$refs.hyUpLoadImg1) {
			// 	// 	this.$refs.hyUpLoadImg1.initUrl('');
			// 	// }
			// },
			
			
			//新增，编辑，查询
			openAddAndQuery(row, msg, type,isCopy){
				this.dialogFormVisibleMsg = msg;
				this.dialogFormVisibleType = type;
				this.dialogFormVisibleIsCopy = isCopy;
				this.ExpressSheetListAddRow = row;
				this.ExpressSheetListAddOpenTime = new Date().getTime();
			},
			SerAddBack(){
				this.initData();
			},
			
			//打开新增编辑，弹窗
			openDioalog(row, msg, type,isCopy) {
				this.openAddAndQuery(row, msg, type,isCopy);
				// console.log(formParm);
				// this.dialogFormVisible = true;
				// this.dialogFormVisibleMsg = msg;
				// this.dialogFormVisibleType = type;
				// let form = Object.assign({}, formParm);
				// console.log('form', form);

				// // // 重置
				// this.resetForm('form');
				// // this.$nextTick(() => {
				// // 	this.$refs.cusSelFuzzy2.clearData();
				// // });

				// // Object.keys(this.form).forEach(key => {
				// // 	this.form[key] = null;
				// // });
				// if (null === formParm) {//新增
					
				// } else {//编辑-查询
					
				// 	try {
				// 		//修改
				// 		this.form = JSON.parse(JSON.stringify(form));
				// 		// this.getChanneByCodeData(this.form.lgsKeyCode)
				// 		let selLgsData = this.selectOption.biz_wh_lgskey_code.find(item => {
				// 			return this.form.lgsKeyCode == item.code;
				// 		});
				// 		//旧-渠道代码-数据字典
				// 		// this.getChanneByCodeData(key);

				// 		//渠道代码
				// 		if (!!selLgsData && !!selLgsData.extendParamJson && !!selLgsData.extendParamJson.channels) {
				// 			this.selectOption.channelList = selLgsData.extendParamJson.channels;
				// 		} else {
				// 			this.selectOption.channelList = [];
				// 		}
				// 		//签名
				// 		if (!!selLgsData && !!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
				// 			this.selectOption.wh_signature_service = selLgsData.extendParamJson.signatures;
				// 		} else {
				// 			this.selectOption.wh_signature_service = [];
				// 		}
						
				// 		this.allChannelList = [];
				// 		if('3'==this.dialogFormVisibleType && selLgsData && selLgsData.extendParamJson){//查询
				// 			this.allChannel = true;
				// 			this.allChannelList = selLgsData.extendParamJson.channels;
				// 		}
						
				// 	} catch (err) {
				// 		//TODO handle the exception
				// 		console.log(err);
				// 		this.$message.warning(this.$t('i18nn_820a3a60bc317776'))
				// 	}
				// 	// this.form = {
				// 	// 	id: form.id,
				// 	// 	userId: form.userId,
				// 	// 	cusName: form.cusName,
				// 	// 	chargeType: form.chargeType,
				// 	// 	confSubList: form.confSubList,
				// 	// 	remark: form.remark
				// 	// };
				// }
			},
			//打开编辑
			editAction(event, row, index) {
				event.stopPropagation();
				this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'), 2,false);
			},
			
			//复制
			copyAction(event, row, index) {
				event.stopPropagation();
				this.openDioalog(row, '复制', 2, true);
			},
			
			
			//运单查询，打开费率查询
			openFeeSer() {
				// event.stopPropagation();
				let row = null;
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length > 1) {
					// row = this.multipleSelection[0];
					this.$message.warning(this.$t('i18nn_b0e16646a7aa97eb') + dataList.length + "条，请选择单条查询")
					return;
				} else if (dataList.length == 1) {
					row = this.multipleSelection[0];
				}
				// event.stopPropagation();
				this.openDioalog(row, this.$t('i18nn_75087cd54a97247c'), 3);
			},
			//打开excel导入
			openImportExcel() {
				this.openImportTime = new Date().getTime();
			},
			
			//运费试算
			// openFeeTest(){
			// 	let row = null;
			// 	//多条
			// 	let dataList = this.multipleSelection;
			// 	if (dataList.length > 1) {
			// 		// row = this.multipleSelection[0];
			// 		this.$message.warning(this.$t('i18nn_b0e16646a7aa97eb') + dataList.length + "条，请选择单条查询")
			// 		return;
			// 	} else if (dataList.length == 1) {
			// 		row = this.multipleSelection[0];
			// 	}
				
			// 	this.PriceTestClacRow = row;
			// 	this.PriceTestClacOpenTime = new Date().getTime();
				
			// },
			
			//运费比价
			// openFeeCompare(){
			// 	let row = null;
			// 	//多条
			// 	let dataList = this.multipleSelection;
			// 	if (dataList.length > 1) {
			// 		// row = this.multipleSelection[0];
			// 		this.$message.warning(this.$t('i18nn_b0e16646a7aa97eb') + dataList.length + "条，请选择单条查询")
			// 		return;
			// 	} else if (dataList.length == 1) {
			// 		row = this.multipleSelection[0];
			// 	}
				
			// 	this.ComparePriceClacRow = row;
			// 	this.ComparePriceOpenTime = new Date().getTime();
			// },
			
			priceSuccessBack(){
				
			},
			//添加配置明细
			// addConfSubListAction() {
			// 	if (!this.form.confSubList) {
			// 		this.form.confSubList = [];
			// 	}
			// 	this.form.confSubList.push({
			// 		dayCount: '', //"1",
			// 		price: '', //this.$t('i18nn_4e5d40d707d90d7d'),
			// 		discount: 1, //this.$t('i18nn_d5beb7f89f0e6b61'),
			// 		buyPrice: '', //this.$t('i18nn_b40390257aa6c985'),
			// 		buyDiscount: 1 //this.$t('i18nn_30e41d362a584108'),
			// 	});
			// },
			// //删除配置明细
			// delConfSubListAction(event, row, index) {
			// 	event.stopPropagation();
			// 	this.form.confSubList.splice(index, 1);
			// },
			//选择查询后的费率保存
			// selAndSubmit(event, row, index) {
			// 	event.stopPropagation();
			// 	console.log('selAndSubmit', row);
			// 	//保存指定渠道
			// 	this.submitForm('form', 1, row);
			// },
			//提交信息
			// submitForm(formName, type, row) {
			// 	if (this.selLgsData && 0 == this.selLgsData.isUse) {
			// 		this.$alert('当前选择的LgsKeyCode为不可用状态', this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 			//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 		return;
			// 	}
				
			// 	this.queryFeeRateRes = {};
			// 	this.queryFeeRateResClac = {};
			// 	this.queryFeeRateResAll = [];
			// 	this.queryFeeRateType = type;
				
			// 	this.$refs[formName].validate(valid => {
			// 		if (valid) {
						
			// 			let formData = Object.assign({}, this.form);
			// 			formData.client = this.$store.state.client;
			// 			if (2 == type) {
			// 				console.log('submitForm',this.dialogFormVisibleType,this.allChannel,this.allChannelList.length);
			// 				if('3'==this.dialogFormVisibleType && !!this.allChannel && this.allChannelList.length>0){//查询全部渠道
			// 					console.log('getAllChannelQueryRate');
			// 					this.getAllChannelQueryRate(formData,this.allChannelList);
			// 				} else {//查询单个渠道
			// 					this.getQueryRate(formData);
			// 				}
							
			// 			} else if (1 == type) {
			// 				if (row) {
			// 					//指定特定渠道
			// 					let channel = row.channel;
			// 					if (channel) {
			// 						formData.channel = channel;
			// 					}
			// 					//fedex cope需要传的字段
			// 					let rateId = row.rateId;
			// 					if (rateId) {
			// 						formData.others.rateId = rateId;
			// 					}
			// 				}
			// 				if (formData.id == null) {
			// 					//新增
			// 					formData.id = null;
			// 					this.postData(this.$urlConfig.WhFuerthertryAdd, [formData],'', (data) => {
			// 						this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
			// 						console.log('this.form.id', data);
			// 						this.dialogFormVisible = false;
			// 						this.getPageData();
			// 					});
			// 				} else {
			// 					//编辑
			// 					this.postData(this.$urlConfig.WhFuerthertryEdit, formData,'', () => {
			// 						this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
			// 						this.dialogFormVisible = false;
			// 						this.getPageData();
			// 					});
			// 				}
			// 			} else if(3 == type){//运费试算
			// 				this.postData(this.$urlConfig.WhCarrFeePriceTestClac, formData, '', (data) => {
			// 					this.queryFeeRateResClac = data.data;
			// 					this.$message.success('查询成功');
			// 				});
			// 			} else if(4 == type){//运费比价
			// 				this.postData(this.$urlConfig.WhCarrFeePriceComparePrice, formData, '', (data) => {
			// 					if(data.data && data.data.carrierPriceArr){
			// 						this.queryFeeRateResAll = data.data.carrierPriceArr;
			// 						this.$message.success('查询成功');
			// 					} else {
			// 						this.$message.warning('查询失败');
			// 					}
			// 				});
			// 			}
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('i18nn_b9221c0e13214d9c'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },

			//查询费率
			// getQueryRate(filterData) {
			// 	filterData.client = this.$store.state.client;
			// 	this.loading = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhFuerthertryQueryRate, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.rows) {
			// 				//表格显示数据
			// 				// this.tableData = data.rows;
			// 				this.queryFeeRateRes = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error(this.$t('tips.requestErrorData'));
			// 			this.loading = false;
			// 		});
			// },
			//查询多个渠道费率
			// async getAllChannelQueryRate (filterData,allChannelList) {
			// 	filterData.client = this.$store.state.client;
			// 	this.queryFeeRateRes = [];
				
			// 	for(let i=0,ln=allChannelList.length;i<ln;i++){
			// 		this.loading = true;
			// 		let formData = filterData;
			// 		formData.channel = allChannelList[i].key;
			// 		console.log('formData',formData,i);
			// 		let resData = await this.$http.put(this.$urlConfig.WhFuerthertryQueryRate, formData);
			// 		console.log('resData',resData,i);
			// 		let data = resData.data;
			// 		if(200 == data.code && data.rows){
			// 			this.queryFeeRateRes = this.queryFeeRateRes.concat(data.rows);
			// 		} else {
			// 			this.$message.warning(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'));
			// 		}
			// 		this.loading = false;
			// 	}
				
			// },
			//校验地址
			// checkAddress() {
			// 	let filterData = this.form.receiverVo;
			// 	this.loading = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhThridOrderInfoCheckAddr, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				//表格显示数据
			// 				// this.tableData = data.rows;
			// 				// this.queryFeeRateRes = data.data;
			// 				this.$message.success("已校验！");
			// 				this.form.receiverVo.addr_type = data.data.addr_type;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error(this.$t('tips.requestErrorData'));
			// 			this.loading = false;
			// 		});
			// },
			//确认收货
			// completeAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('completeAction', row);

			// 	this.dialogIsLiveUpload = true;

			// 	this.IsLiveUploadData.id = row.id;
			// 	this.IsLiveUploadData.isLiveUpload = '0';
			// },
			//确认收货提交
			// submitReceiving() {
			// 	this.postData(
			// 		this.$urlConfig.WhInGoodsSureReceived + '/' + this.IsLiveUploadData.id,
			// 		{
			// 			isLiveUpload: this.IsLiveUploadData.isLiveUpload
			// 		},
			// 		() => {
			// 			this.dialogIsLiveUpload = false;
			// 			this.initData();
			// 			this.$message.success(this.$t('9f30371831a98237'));
			// 		}
			// 	);
			// },
			//删除
			delAction(event, row) {

				this.$confirm('确定删除吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(
							this.$urlConfig.WhFuerthertryDel + '/' + row.id, {},'delete',
							() => {
								this.$message.success(this.$t('i18nn_de017dafc266aa03'));
								this.initData();
							}
							
						);
					})
					.catch(() => {

					});


			},

			//提交打单
			submitOrderRateAction(event, row) {
				event.stopPropagation();
				// this.getPriterOrderRate(row,index,row.id);
				let parm = [];
				if (row) {
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
						return;
					}
					// 0,10,20 ->草稿,提交,已审核
					// console.log(dataList.findIndex(item=> ('0'!==item.status && '10'!==item.status && '20'!==item.status)))
					// if (dataList.findIndex(item=> ('0'!==item.status && '10'!==item.status && '20'!==item.status))>-1) {
					//   this.$message.warning('只有草稿、提交、审核状态才可以提交打单');
					//   return;
					// }
					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
				}
				this.$confirm(this.$t('i18nn_204ffab8a6e01870') + parm.length + '条数据 提交打单吗?', this.$t(
					'i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhFuerthertryCommit, parm,'', () => {
							this.initData();
							this.$message.success(this.$t('i18nn_1cb9e763fc0324fa'));
						});
					})
					.catch(() => {

					});
			},
			// //撤销打单
			// CancelPrintingAction(event, row) {
			// 	event.stopPropagation();
			// 	// this.getPriterOrderRate(row,index,row.id);
			// 	let parm = [];
			// 	if (row) {
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
			// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
			// 		//   return;
			// 		// }

			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}
			// 	this.$confirm(this.$t('i18nn_204ffab8a6e01870') + parm.length + '条数据 撤销打单吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhThridLabelCancelPrinting, parm, () => {
			// 				this.initData();
			// 				this.$message.success(this.$t('i18nn_778d871227234f32'));
			// 				// this.$router.push({name:'WhPackage',query:{open:'add'}});
			// 			});
			// 		})
			// 		.catch(() => {
			// 			// this.$message({
			// 			//   type: 'info',
			// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
			// 			// });
			// 		});
			// },
			// //取消面单
			// CancelOrderAction(event, row) {
			// 	event.stopPropagation();
			// 	// this.getPriterOrderRate(row,index,row.id);
			// 	let parm = [];
			// 	if (row) {
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
			// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
			// 		//   return;
			// 		// }

			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}
			// 	this.$confirm(this.$t('i18nn_204ffab8a6e01870') + parm.length + '条数据 取消面单吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhThridLabelCancelOrder, parm, () => {
			// 				this.initData();
			// 				this.$message.success(this.$t('i18nn_854a071f3cbf2761'));
			// 				// this.$router.push({name:'WhPackage',query:{open:'add'}});
			// 			});
			// 		})
			// 		.catch(() => {
			// 			// this.$message({
			// 			//   type: 'info',
			// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
			// 			// });
			// 		});
			// },
			//批量复核
			// authAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('authAction', row);

			// 	this.$confirm(this.$t('hytxs0000043'), this.$t('i18nn_daaaeb1b7b22b126'), {
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhInGoodsAuth + '/' + row.id, {}, () => {
			// 				this.initData();
			// 				this.$message.success(this.$t('9f30371831a98237'));
			// 			});
			// 		})
			// 		.catch(() => {});
			// },

			//提交数据
			postData(url, formData, type,callback) {
				// let _this = this;
				this.loading = true;
				let http = {};
				if ('delete' == type) {
					http = this.$http.delete(url, formData);
				} else {
					http = this.$http.put(url, formData);
				}
				http
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.submitError');
							}
							this.$alert(data.msg, this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
			//lgsCode改变
			// lgsCodeChange(key) {
			// 	this.form.channel = "";
			// 	this.form.signature = "";
			// 	this.selLgsData = this.selectOption.biz_wh_lgskey_code.find(item => {
			// 		return key == item.code;
			// 	});
			// 	// this.getChanneByCodeData(key);
			// 	//渠道代码
			// 	if (!!this.selLgsData && !!this.selLgsData.extendParamJson && !!this.selLgsData.extendParamJson.channels) {
			// 		this.selectOption.channelList = this.selLgsData.extendParamJson.channels;
			// 	} else {
			// 		this.selectOption.channelList = [];
			// 	}
			// 	//签名
			// 	if (!!this.selLgsData && !!this.selLgsData.extendParamJson && !!this.selLgsData.extendParamJson.signatures) {
			// 		this.selectOption.wh_signature_service = this.selLgsData.extendParamJson.signatures;
			// 	} else {
			// 		this.selectOption.wh_signature_service = [];
			// 	}
			// 	//cope_fedex 原-> biz_wh_lgskey_cope_fedex 发货地址不可编辑-
			// 	// cope_ups   cope_usps
			// 	// if('cope_fedex' === key || 'cope_ups' === key || 'cope_usps' === key){
			// 	//限制发货地址
			// 	if (this.onlySenderLgsCode.includes(key)) {
			// 		this.getCopeShipFrom(key);
			// 	}
			// 	// 仅查询
			// 	this.allChannelList = [];
			// 	if('3'==this.dialogFormVisibleType && this.selLgsData && this.selLgsData.extendParamJson){
			// 		this.allChannel = true;
			// 		this.allChannelList = this.selLgsData.extendParamJson.channels;
			// 	}
			// },
			//cope改变
			// ChangCopeShip(v) {
			// 	let ship = this.CopeShipfromList.find(item => {
			// 		return item.uuid == v;
			// 	});
			// 	console.log('ship', ship);
			// 	let shipAddr = ship.address;

			// 	if (shipAddr) {
			// 		this.form.senderVo.firstname = shipAddr.name;
			// 		// this.form.senderVo.company = ship.name;
			// 		if (shipAddr.addresses && shipAddr.addresses[0]) {
			// 			this.form.senderVo.addr1 = shipAddr.addresses[0];
			// 		}
			// 		if (shipAddr.addresses && shipAddr.addresses[0]) {
			// 			this.form.senderVo.addr2 = shipAddr.addresses[1];
			// 		}
			// 		this.form.senderVo.city = shipAddr.city;
			// 		this.form.senderVo.province = shipAddr.state_province_code;
			// 		this.form.senderVo.postcode = shipAddr.postal_code;
			// 		this.form.senderVo.country = shipAddr.country_code;
			// 		this.form.senderVo.mobile = shipAddr.phone;
			// 		this.form.senderVo.uuid = shipAddr.uuid;
			// 	}

			// },
			//旧-->biz_wh_lgskey_cope_fedex 发货地址
			//新--> 'cope_fedex' === key || 'cope_ups' === key || 'cope_usps' === key
			//限制发货地址
			// getCopeShipFrom(lgsCode) {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhPriterOrderCopeShipfrom + "?lgsCode=" + lgsCode, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log('查询cope_fedex，请求成功');
			// 			// console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.CopeShipfromList = data.rows;
			// 				if (this.CopeShipfromList[0] && this.CopeShipfromList[0].uuid) {
			// 					this.selCopeShip = this.CopeShipfromList[0].uuid;
			// 					this.ChangCopeShip(this.selCopeShip);
			// 				}
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			// console.log(error);
			// 			this.loading = false;
			// 			// console.log('查询cope_fedex接口，请求失败');
			// 			this.$message.error(this.$t('tips.requestErrorData'));
			// 		});
			// },
			//usp 渠道2-lgs列表
			// getPriterOrderUps2LgsList() {
			// 	this.loading = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhPriterOrderUps2LgsList, {})
			// 		.then(({ data }) => {
			// 			console.log('查询lgs列表信息');
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.PrtOrderExpressList = data.rows;
			// 				if (this.PrtOrderExpressList.length > 0) {
			// 					if(0 != this.PrtOrderExpressList[0].isUse){
			// 						this.PrtOrderExpressData = this.PrtOrderExpressList[0].code;
			// 					}
			// 				}

			// 				// callback(data.rows);
			// 				// this.PriterOrderRate3 = [data.data];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询信息失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 				// this.PriterOrderRate3_err_msg = data.msg;
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('lgs列表信息接口，请求失败');
			// 			this.loading = false;
			// 			this.$message.error('查询lgs信息失败,请重试!');
			// 			// this.PriterOrderRate3_err_msg = '查询信息失败,请重试!';
			// 		});
			// },
			//渠道代码值
			// getChanneByCodeData(key){
			// 	console.log(key);
			// 	this.loading = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, [key])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.channelList = data.data[key];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading = false;
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.requestErrorData'));
			// 		});
			// },
			//lgscode
			// getLGSByCodeData() {
			// 	this.loading = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
			// 			isUse: '1'
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询lgs，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.biz_wh_lgskey_code = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = 'lgs请求失败';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading = false;
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('lgs请求失败！');
			// 		});
			// },

			//查询FBA地址
			// searchFbaAddress(type) {
			// 	console.log('searchFbaAddress');
			// 	let fbaAddrCode = "";
			// 	if (1 == type) {
			// 		fbaAddrCode = this.fbaAddrCode;
			// 	} else if (2 == type) {
			// 		fbaAddrCode = this.fbaAddrCode2;
			// 	}
			// 	if (!fbaAddrCode) {
			// 		this.$message.warning("请输入FBA仓库号");
			// 		return;
			// 	}
			// 	if (1 == type) {
			// 		this.form.receiverVo.province = "";
			// 		this.form.receiverVo.city = "";
			// 		this.form.receiverVo.addr1 = "";
			// 		this.form.receiverVo.addr2 = "";
			// 		this.form.receiverVo.postcode = "";
			// 		this.form.receiverVo.mobile = "";
			// 		this.form.receiverVo.mail = "";
			// 		this.form.receiverVo.company = "";
			// 		this.form.receiverVo.firstname = "";
			// 		this.form.receiverVo.lastname = "";
			// 	} else if (2 == type) {
			// 		this.form.senderVo.province = "";
			// 		this.form.senderVo.city = "";
			// 		this.form.senderVo.addr1 = "";
			// 		this.form.senderVo.addr2 = "";
			// 		this.form.senderVo.postcode = "";
			// 		this.form.senderVo.mobile = "";
			// 		this.form.senderVo.mail = "";
			// 		this.form.senderVo.company = "";
			// 		this.form.senderVo.firstname = "";
			// 		this.form.senderVo.lastname = "";
			// 	}
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + fbaAddrCode, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_776496ec446f21f9'));
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.data) {
			// 				// this.form.country = addrData.country;
			// 				// this.form.state = addrData.state;
			// 				// this.form.city = addrData.city;
			// 				// this.form.postalCode = addrData.zipCode;
			// 				// this.form.addr = addrData.address;
			// 				// this.form.phone = addrData.phone;
			// 				let findWh = data.data;
			// 				if (1 == type) {
			// 					this.form.receiverVo.province = findWh.state;
			// 					this.form.receiverVo.city = findWh.city;
			// 					this.form.receiverVo.addr1 = findWh.address;
			// 					this.form.receiverVo.addr2 = "";
			// 					this.form.receiverVo.postcode = findWh.zipCode;
			// 					this.form.receiverVo.mobile = findWh.phone;
			// 					this.form.receiverVo.mail = "";
			// 					this.form.receiverVo.company = "";
			// 					this.form.receiverVo.firstname = findWh.name;
			// 					this.form.receiverVo.lastname = "";
			// 				} else if (2 == type) {
			// 					this.form.senderVo.province = findWh.state;
			// 					this.form.senderVo.city = findWh.city;
			// 					this.form.senderVo.addr1 = findWh.address;
			// 					this.form.senderVo.addr2 = "";
			// 					this.form.senderVo.postcode = findWh.zipCode;
			// 					this.form.senderVo.mobile = findWh.phone;
			// 					this.form.senderVo.mail = "";
			// 					this.form.senderVo.company = "";
			// 					this.form.senderVo.firstname = findWh.name;
			// 					this.form.senderVo.lastname = "";
			// 				}

			// 			} else {
			// 				// if (!data.msg) {
			// 				// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
			// 				// }
			// 				// this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.errorData'));
			// 			this.loading = false;
			// 			// this.$message.error('查询失败!');
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_weight_unit', 'wh_vol_unit', 'wh_label_size', 'wh_size_unit','wh_signature_service'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_label_size = data.data['wh_label_size'];
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.requestErrorData'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	@import url(@/assets/css/tableStyle.less);
</style>